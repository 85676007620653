<template>
  <div class='maintain'>
    <img src="@/assets/maintain.png" alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.maintain {
  width: 100%;
  height: 100vh;
  img {
    margin: 0 auto;
    // width: 80%;
    height: 100%;
  }
}
</style>
